<template>
  <div class="osk-layout-default">
    <ClientOnly>
      <div
        v-if="needPhoneVerify && phoneVerifyWebPopup?.blackStick"
        class="osk-layout-default__auth-line"
        @click="openPhoneDialog"
      >
        <span class="osk-layout-default__auth-line-link">{{ $t('layouts.default.phone1') }}</span>&nbsp;{{ $t('layouts.default.phone2') }}

        <UikitIcon
          class="osk-ml-xs osk-layout-default__auth-line-icon"
          name="ArrowRight"
          size="s"
          color="white"
        />
      </div>
    </ClientOnly>

    <WebToAppDownloadAppMobileTop
      v-if="$device.isMobile"
      v-intersection-observer="onIntersectionObserver"
      class="osk-mx-md"
      app-link="https://oskelly.onelink.me/kabM/iktx9f3i"
    />

    <Header
      :style="{
        transform: `translateY(-${transform}px)`
      }"
    />

    <div class="osk-layout-default__container">
      <!-- На мобилке нету кнопки Выйти совсем. Если надо тестировать мобилку можно раскомментить. -->
      <!-- <div v-if="isAuth">
        <a
          href="/logout"
          class="osk-user-menu__link"
          @click.prevent="handleLogout"
        >
          <UikitLoaderInner
            v-if="logoutLoading"
            small
          />

          Выйти
        </a>
      </div> -->
      <slot />
    </div>

    <Footer
      :class="{
        'osk-layout-default__footer': true,
        'osk-layout-default__footer_hide': hideFooter,
      }"
    />

    <BusinessMenuBottomMobile class="osk-desktop-hidden" />

    <CatalogMenuMobileDialog
      v-if="isMounted && !isDesktop"
      v-model="showCatalogMenu"
    />
    <HeaderSearchMobileDialog
      v-if="isMounted && !isDesktop"
      v-model="isHeaderSearchMobileDialog"
    />
    <NotificationsDialog
      v-if="isMounted"
      v-model="isNotificationDialog"
    />

    <template v-if="(!isAuth || isAuthNow) && isMounted">
      <AuthOldDialog
        v-if="!PHONE_REGISTRATION_WEB_EXPERIMENT_ON"
        v-model="isAuthDialogOpen"
        @success="phoneVerifiedStorage = undefined"
      />

      <AuthDialog
        v-if="PHONE_REGISTRATION_WEB_EXPERIMENT_ON"
        v-model="isAuthDialogOpen"
        @success="phoneVerifiedStorage = undefined"
        @register="handleRegister"
      />

      <AuthRegisterDialog
        v-if="PHONE_REGISTRATION_WEB_EXPERIMENT_ON"
        v-model="isRegisterDialogOpen"
      />
    </template>

    <AuthPhoneVerifyDialog
      v-if="needPhoneVerify && isMounted"
      v-model="isPhoneVerifyDialog"
    />

    <CountryDialog
      v-if="countries.length > 0 && isMounted"
      v-model="isCountryDialogOpen"
    />

    <ClientOnly>
      <ErrorDialog v-model="isErrorDialog" />
    </ClientOnly>

    <ClientOnly>
      <notifications
        class="osk-notify-group osk-notify-group_center"
        group="mainNotifications"
        :duration="4000"
        classes="osk-notify"
        position="top center"
        width="auto"
        animation-name="slide-down-notify"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { useNotification } from '@kyvg/vue3-notification';
import { vIntersectionObserver } from '@vueuse/components';

const { t } = useI18n();
const url = useRequestURL();
const route = useRoute();
const router = useRouter();
const { notify } = useNotification();
const buildId = useAppConfig().nuxt?.buildId;

console.log('useAppConfig', buildId);
console.log('useRuntimeConfig', useRuntimeConfig().app.buildId);

const {
  isAuth,
  isAuthDialogOpen,
  nextAuthLink,
  account,
  isRegisterDialogOpen,
} = useAccountStore();
const {
  needPhoneVerify,
  isPhoneVerifyDialog,
  openPhoneVerifyDialog,
  phoneVerifiedStorage,
  phoneVerifyWebPopup,
} = usePhoneVerification();
const { isCountryDialogOpen, countries } = useInternational();
const {
  isHeaderSearchFull,
  isNotificationDialog,
  isHeaderSearchMobileDialog,
  showCatalogMenu,
} = useHeader();
const {
  usageSearchId,
  isGoToNextPageFromSearch,
} = useHeaderSearch();
const {
  changeActiveMenuByRoute,
  stopChangingActiveMenu,
} = useActiveMenuCategory();
const { isDesktop, isMounted } = useUiBreakpoints();
const { isErrorDialog } = useErrorDialog();
const { isMorePages } = useMorePagesData();
const { shouldMobileBannerHide } = useDialog();
const { PHONE_AUTH_WEB_EXPERIMENT, PHONE_REGISTRATION_WEB_EXPERIMENT_ON } = useExperimentsStore();

useHead({
  script: !isAuth.value ? [
    {
      src: 'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js',
    },
  ] : [],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1',
    },
    {
      name: 'description',
      content: t('meta.description'),
    },
    {
      name: 'og:title',
      content: t('meta.title'),
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:url',
      content: url.href + route.path,
    },
    {
      name: 'og:image',
      content: t('meta.image'),
    },
    {
      name: 'og:description',
      content: t('meta.description'),
    },
  ],
});

const HEADER_MOBILE_CLASS = '.osk-header-mobile';

const isPromoVisible = ref(true);
const transform = ref(0);
const isAuthNow = ref(false);

// TODO CHECK
const hideFooter = computed(() => isMounted.value && !isDesktop.value && isMorePages.value);

// На мобилке нету кнопки Выйти совсем. Если надо тестировать мобилку можно раскомментить. //////////////
// const logoutLoading = ref(false);

// const { createApiInstance } = useApi();
// const logoutApi = createApiInstance(Logout);

// async function handleLogout() {
//   logoutLoading.value = true;

//   const { error } = await useAsyncData(() => logoutApi.postApiV2Logout());

//   if (error.value) {
//     logoutLoading.value = false;
//   } else {
//     phoneVerifiedStorage.value = undefined;

//     window.location.reload();
//   }
// }
// //////////////

function onIntersectionObserver([{ isIntersecting }]: IntersectionObserverEntry[]) {
  isPromoVisible.value = isIntersecting;
}

function updateHeaderTransform() {
  if (!isDesktop.value) {
    transform.value = document.querySelector(HEADER_MOBILE_CLASS)?.getBoundingClientRect().top || 0;
  }
}

function openPhoneDialog() {
  openPhoneVerifyDialog({
    chapter: 'productSceneContactSeller',
    phoneText: t('layouts.default.phoneForFutureOrders'),
  });
}

function handleRegister() {
  isRegisterDialogOpen.value = true;
}

watch(() => route.fullPath, (value) => {
  if (usageSearchId.value && !isGoToNextPageFromSearch.value) {
    usageSearchId.value = null;
  }

  isGoToNextPageFromSearch.value = false;
  isHeaderSearchFull.value = false;

  const isProduct = value.includes('/products/');
  const isHomeBanner = value.includes('/homeBanner/');

  if (!stopChangingActiveMenu.value && !isProduct && !isHomeBanner) {
    changeActiveMenuByRoute(route);
  }

  stopChangingActiveMenu.value = false;
}, { immediate: true });

watch(isHeaderSearchFull, () => {
  updateHeaderTransform();
});

watch(shouldMobileBannerHide, () => {
  updateHeaderTransform();
});

watchOnce(() => isAuth.value, async (value) => {
  if (value) {
    isAuthNow.value = true;

    if (nextAuthLink.value) {
      const link = typeof nextAuthLink.value === 'function' ? nextAuthLink.value() : nextAuthLink.value;

      window.location.href = link;
    } else {
      window.location.reload();
    }
  }
});

onMounted(async () => {
  const { errorMessage } = route.query;

  if (errorMessage) {
    nextTick(() => {
      notify({
        group: 'mainNotifications',
        type: 'error',
        text: errorMessage as string,
      });
      router.push({ query: {} });
    });
  }

  if (isPhoneVerifyDialog.value) {
    isPhoneVerifyDialog.value = false;
  }

  if (!phoneVerifiedStorage.value && needPhoneVerify.value && PHONE_AUTH_WEB_EXPERIMENT.value?.valueKey === 'on') {
    await nextTick();

    openPhoneVerifyDialog({ chapter: 'auth' });

    phoneVerifiedStorage.value = true;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push ({
    event: 'userId',
    userId: account.value?.id,
  });
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-layout-default {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  padding-top: 0;
  position: relative;

  &__auth-line {
    @include font-style($font-size-caption, normal, $font-weight-medium);
    background: $black;
    color: #fff;
    text-align: center;
    cursor: pointer;
    padding: 10px 16px;
    position: relative;
    z-index: 10;

    @include media-query(lg-and-up) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 16px;
    }
  }

  &__auth-line-link {
    text-decoration: underline;
    font-weight: 600;

    @include media-query(lg-and-up) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__auth-line-icon {
    display: none !important;

    @include media-query(lg-and-up) {
      display: inline-flex !important;
    }
  }

  &__container {
    flex: 1;
  }

  &__footer {
    &_hide {
      display: none;
    }
  }

  .osk-notify-group {
    min-width: 348px;
  }

  .vue-notification-wrapper {
    padding-top: 8px;
  }
}
</style>
